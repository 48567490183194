import { notify } from 'front-commons/ds';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { matchPath, Location, NavigateFunction } from 'react-router-dom';
import { onEnvironment } from 'shared/environment';
import { mainCustomerPage } from 'stores/customer/helpers';
import { CustomerReducer } from 'stores/customer/interfaces';
import { FeaturesReducer } from 'stores/features/interfaces';
import { getStore } from 'stores/helpers';
import {
	ContextsAvailable,
	DynamicPageImport,
	GeneratePageParams,
	GetStartedFromStateParams,
	PageGlobalProps,
	PageSchema,
} from './interfaces';

export const RemoveHTMlTags = (html: React.ReactNode | string, separator = '') => {
	if (!html) return '';
	return renderToString(html as any).replace(/<[^>]*>/g, separator);
};

export const providerHandler = ({ page: Page, provider: Provider, ...rest }: any) => {
	if (Provider)
		return (
			<Provider>
				<Page {...rest} />
			</Provider>
		);

	return <Page {...rest} />;
};

export const comparePaths = (patternPath: string, atualPath: string) => {
	const match = matchPath(patternPath, atualPath);

	return Boolean(match);
};

export const needRedirectByContext = (
	needContexts: PageGlobalProps['needContexts'],
	{ pharmacyContext, customerContext }: ContextsAvailable,
) => {
	const { token } = getStore().customerReducer;

	if (!token) {
		return '/login';
	}

	if (needContexts?.includes('pharmacy') && !pharmacyContext?.id) {
		if (customerContext?.id) {
			notify.negative({ description: 'Por favor, selecione uma farmácia para prosseguir!' });
		}

		return mainCustomerPage();
	}

	return false;
};

export const pageGenerator = (pagesContext: PageSchema) => {
	const pages: GeneratePageParams[] = [];
	Object.values(pagesContext).forEach((item) => {
		pages.push(...item);
	});

	const importPages = import.meta.glob('pages/**') as DynamicPageImport;

	if (onEnvironment('development')) {
		pages.push({
			pageDirPath: '/Sandbox',
			title: 'Debug page',
			pathname: '/test',
			headerType: 'dynamic',
		});

		pages.push({
			pageDirPath: '/Playground',
			title: 'Playground page',
			pathname: '/playground',
			headerType: 'hide',
			showFooter: false,
		});
	}

	return Object.values(pages).map(({ pageDirPath, ...params }) => ({
		page: React.lazy(importPages[`/src/pages${pageDirPath}/index.tsx`]),
		...params,
	}));
};

export const getStartedFromState = ({ pathnameRedirecting, location, hasToken }: GetStartedFromStateParams) => {
	const blacklist = ['/', '/minhas-farmacias', '/minhas-farmacias/acessos'];

	const isLoginRedirect = pathnameRedirecting === '/login';
	const userForceLogout = hasToken && !isLoginRedirect;

	if (userForceLogout || blacklist.includes(location.pathname)) {
		return null;
	}

	return { startedFrom: `${location.pathname}${location.search}` };
};

export const canAccessThisPage = (
	customerProvider: CustomerReducer['provider'],
	location: Location,
	customerToken: CustomerReducer['token'],
) => {
	if (customerProvider === null) {
		const queryParams: Record<string, string> = location.search
			.replace('?', '')
			.split('&')
			.reduce((acc, cur) => {
				const [key, value] = cur.split('=');
				return { ...acc, [key]: value };
			}, {});

		if (!queryParams.token) return '/login';

		return false;
	}

	if (['PARCEIRO_HYPERA', 'ZECA_COPILOTO'].includes(customerProvider) && customerToken) return false;

	return '/login?logout=catp';
};

export const handleWatchCustomerPhone = (
	phoneNumber: CustomerReducer['data']['phoneNumber'],
	phoneNumberConfirmed: CustomerReducer['data']['phoneNumberConfirmed'],
	customerId: CustomerReducer['data']['id'],
	pathname: string,
	protectType: PageGlobalProps['protectType'],
	navigate: NavigateFunction,
	features: FeaturesReducer,
) => {
	if (!features.MANDATORY_CUSTOMER_PHONE_REGISTER) return;

	const hasPhoneANDIsConfirmedORNoIdYet = (phoneNumber && phoneNumberConfirmed) || !customerId;
	if (hasPhoneANDIsConfirmedORNoIdYet) return;

	const phoneRegisterFlux = ['/cadastro/celular/validacao', '/cadastro/celular'];
	if (['not_logged', 'public'].includes(protectType || '') || phoneRegisterFlux.includes(pathname)) return;

	navigate('/cadastro/celular', { replace: true });
};

export const headScripts = () => (
	<>
		<script async src={import.meta.env.VITE_APP_INSIDER_ID || '//hyperab2bbr.api.useinsider.com/ins.js?id=10009711'} />
		<script type="text/javascript" src="//cdn-4.convertexperiments.com/js/10046060-100411793.js" />

		<script async src="/gtmscript.js" nonce="googletm" />
		<script async src="/vwoscript.js" nonce="visualwp" />
		<script async src="/clarityscript.js" nonce="clarityms" />
	</>
);
