import { Button, Flex, Skeleton, Typography, notify } from 'front-commons/ds';
import { useDynamicLoading } from 'front-commons/hooks';
import { useMemo } from 'react';
import AddToBasketButton from 'containers/AddToBasketButton';
import SuggestedProductTag from 'containers/Tags/SuggestedProductTag';
import { manageBasketProductAmount } from 'services/basket';
import { updateProductByPosId } from 'services/import';
import { updateCartGTM } from 'shared/gtm';
import useBasket from 'stores/basket';
import useCustomer from 'stores/customer';
import useDrawer from 'stores/drawer';
import { SuggestedProductCardProps } from './interfaces';
import { SuggestedProductCardContainer } from './styles';
import { Separator } from '../PromotionDrawerCard/styles';
import { ProductImg } from '../SimpleProduct/styles';

export default function SuggestedProductCard({
	id,
	ean13,
	posId,
	imageURL,
	moreAbout,
	description,
	businessUnitId: BUId,
	suggestedQuantity,
	suggestionGroupId,
	suggestionGroupName,
	selectedDistribution,
	orderImportProductId,
	onAddSuggestion,
	handleRefetchItemList,
	brandName,
	categoryName,
	businessUnitName,
}: SuggestedProductCardProps) {
	const { handleLoading } = useDynamicLoading<'loading' | 'waitingBasket'>();

	const {
		basketStore: { basket: basketData },
	} = useBasket();
	const { handleCloseAllDrawers } = useDrawer();
	const { handleHasPermission } = useCustomer();

	const hasPermission = useMemo(() => handleHasPermission('BASKET_EDIT'), []);
	const productBasket = useMemo(
		() => basketData?.baskets?.find((basket) => basket.products.find((product) => product.id === id)),
		[basketData],
	);

	const dispatchGTM = (type: string, newQuantity: number) => {
		const { unitDiscount, finalPrice, distributorName } = selectedDistribution;

		const total = (finalPrice || 0) * (newQuantity || suggestedQuantity);

		const products = [
			{
				ean13,
				description,
				price: finalPrice || 0,
				quantity: newQuantity || suggestedQuantity,
				brandName,
				categoryName,
				businessUnitName,
				discount: unitDiscount,
				affiliation: distributorName,
				combo: false,
				suggestionGroupName,
			},
		];

		updateCartGTM(type, total, products);
	};

	const handleAddSuggestion = async () => {
		try {
			handleLoading().set(['loading']);
			if (orderImportProductId) {
				await updateProductByPosId({
					removed: false,
					quantity: suggestedQuantity,
					distributorId: selectedDistribution.toString(),
					orderImportProductId,
				});
				handleRefetchItemList?.();
				handleCloseAllDrawers();
			} else {
				await manageBasketProductAmount(posId, {
					quantity: suggestedQuantity,
					productId: id,
					distributorId: selectedDistribution.distributorId,
					suggestionGroupId,
					suggestionGroupName,
				});
				onAddSuggestion();
				dispatchGTM('add_to_cart', suggestedQuantity);
			}
			handleLoading().set(['waitingBasket']);
			notify.positive({ description: 'Sugestão adicionada com sucesso!' });
		} catch (error) {
			notify.negative({
				description: 'Não foi possível adicionar essa sugestão ao seu carrinho! Por favor, tente novamente.',
			});
		} finally {
			handleLoading().remove(['loading', 'waitingBasket']);
		}
	};

	return (
		<SuggestedProductCardContainer direction="column" gap="16px" margin="16px 0 32px">
			<Flex margin="0 0 -8px">
				<SuggestedProductTag suggestionGroupName={suggestionGroupName} ignorePosition fullRadius />
			</Flex>

			<Typography color="--text-primary" variant="Headline/H2 Bold">
				{description.toPascalCase(true)}
			</Typography>

			<Flex gap="8px" alignItems="flex-start" wrap={{ small: 'wrap', medium: 'nowrap' }}>
				<Flex width="80px" height="80px" alignItems="center" justifyContent="center">
					<ProductImg
						src={`${import.meta.env.VITE_STATIC_STORAGE_BASE}${imageURL!}`}
						alt={`Imagem do produto ${description.capitalize()}`}
						size="80px"
					/>
				</Flex>
				<Flex gap="8px" direction="column" width="100%" flex={{ small: '1', medium: 'initial' }}>
					<Typography color="--text-primary" variant="Paragraph/Semibold">
						{moreAbout}
					</Typography>

					<Typography variant="ParagraphSmall/Regular" as="span" color="--text-primary">
						<Typography as="span" color="--text-primary" variant="Paragraph/Semibold">
							EAN:{' '}
						</Typography>
						{ean13}
					</Typography>
				</Flex>
				<Flex
					gap="8px"
					direction={{ medium: 'column' }}
					alignItems={{ small: 'center', medium: 'flex-end' }}
					width="100%"
					margin={{ small: '0 0 16px', medium: '0' }}
				>
					<Typography variant="ParagraphSmall/Regular">Recomendado para você:</Typography>
					<Typography variant="Paragraph/Semibold" style={{ fontSize: '18px' }}>
						{suggestedQuantity} {suggestedQuantity > 1 ? 'unidades' : 'unidade'}
					</Typography>
				</Flex>
			</Flex>

			<Flex justifyContent={{ medium: 'flex-end' }} gap="0px" margin="-16px 0 16px">
				{handleLoading().get(['waitingBasket']) && <Skeleton height="40px" width="140px" />}

				{!productBasket && !handleLoading().get(['waitingBasket']) && (
					<Button
						onClick={handleAddSuggestion}
						width="100%"
						maxWidth="140px"
						loading={handleLoading().get(['loading'])}
					>
						Adicionar
					</Button>
				)}

				{productBasket && !handleLoading().get(['waitingBasket']) && (
					<AddToBasketButton
						label="Adicionar ao carrinho"
						hasPermission={hasPermission}
						disabled={!selectedDistribution?.finalPrice}
						buId={BUId}
						productId={id}
						distributorId={selectedDistribution?.distributorId!}
						initialQuantity={productBasket.products.find((product) => product.id === id)?.quantity || undefined}
						width="100%"
						counterOptions={{
							width: '100%',
							maxWidth: '140px',
							hasPermission,
						}}
						suggestionGroupId={productBasket?.suggestionGroupId}
						suggestionGroupName={productBasket?.suggestionGroupName}
						onUpdateBasketType={dispatchGTM}
					/>
				)}
			</Flex>

			<Separator className="separator" />
		</SuggestedProductCardContainer>
	);
}
