import {
	Flex,
	getThemeFontSize,
	getThemeFontWeight,
	getThemeMedia,
	getThemeShadow,
	getThemeSpace,
	Typography,
} from 'front-commons/ds';
import styled, { css } from 'styled-components';
import { CardProps } from './interfaces';

export const ProductContainer = styled(Flex) <{ setMaxHeight?: boolean }>`
	background: var(--surface-white);
	box-shadow: ${getThemeShadow('1')};
	border-radius: 8px;
	opacity: 1;
	position: relative;
	width: 100%;
	overflow: hidden;
	max-width: 300px;

	@media screen {
		@media (min-width: ${getThemeMedia('large')}) {
			${({ setMaxHeight }) =>
		setMaxHeight &&
		css`
					max-height: 400px;
					height: 400px;
				`}
		}
	}
`;

export const ProductImg = styled.img<{ size?: `${string}px` }>`
	max-width: ${({ size }) => size || '96px'};
	max-height: ${({ size }) => size || '96px'};
	object-fit: cover;
	object-position: center;
`;

export const ProductTitle = styled(Typography)`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	line-clamp: 3;
`;

export const ProductDiscountPrice = styled(Typography)`
	text-decoration: line-through;
	margin-right: ${getThemeSpace('4')};
`;

export const ProductPrice = styled(Typography)`
	margin-right: ${getThemeSpace('4')};
`;

export const ProductConflictAlert = styled.div`
	background-color: var(--surface-accent-light);
	padding: 8px 10px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 0px 0px 8px 8px;
	user-select: none;

	button {
		display: inline-block;
		padding: 0;
		font-size: ${getThemeFontSize('14')};
		height: auto;
		margin-left: 2px;

		p {
			font-weight: ${getThemeFontWeight('bold')} !important;
		}
	}

	.cta-text::before {
		content: 'Clique e veja a';
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			padding: 8px 12px;

			button {
				text-decoration: underline;
				color: var(--text-link);

				&:hover {
					color: var(--text-link);
				}
			}

			.cta-text::before {
				content: 'Confira a';
			}
		}
	}
`;

export const BoxButtons = styled(Flex)`
	flex: 1;
`;

const transitionDuration = 200;

const animationButtonHover = css`
	&::before,
	&::after {
		width: calc(100% - 2px);
		height: calc(100% - 2px);
		transition: all ${transitionDuration}ms linear;
	}

	&::after {
		transition-delay: ${transitionDuration}ms;
		height: 100%;
	}
`;

export const AnimatedButtonWrapper = styled(Flex) <{
	forceAnimation?: boolean;
	forceAnimationOnMobile?: boolean;
	loading?: boolean;
}>`
	overflow: hidden;
	border-radius: 8px;
	padding: 1px; // caso exclusivo
	position: relative;
	height: 40px;
	box-sizing: border-box;

	&::before,
	&::after {
		content: '';
		display: block;
		background-color: var(--semantic-promo-text);
		width: 0;
		height: 2px;
		position: absolute;
		top: 0;
		left: 8px;
		z-index: 0;
		transition: all ${transitionDuration}ms ease-out;
		transition-delay: ${transitionDuration}ms;
	}

	&::before {
		background: var(--surface-white);
		background: linear-gradient(
			8deg,
			var(--surface-white) 0%,
			var(--surface-white) 21%,
			var(--semantic-success-base) 21%
		);
	}

	&::after {
		top: auto;
		left: auto;
		bottom: 0;
		right: 2px;
		transition-delay: 0ms;
	}

	${({ loading, forceAnimation }) =>
		!loading &&
		css`
			${forceAnimation && animationButtonHover}

			&:hover {
				${animationButtonHover}
			}
		`}

	& > button {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;

		height: 100%;
		min-height: auto;
		width: 100%;

		background-color: var(--surface-white);
		border-color: transparent;
		border-radius: 8px;

		z-index: 1;
		cursor: pointer;
	}

	@media screen {
		@media (max-width: 767px) {
			${({ forceAnimationOnMobile }) => forceAnimationOnMobile && animationButtonHover}
		}
	}
`;

export const DistributorsContent = styled.div<{ viewPriceComparison: CardProps['viewPriceComparison'] }>`
	margin: auto -16px;
	width: calc(100% + 32px);

	${({ viewPriceComparison }) =>
		viewPriceComparison &&
		css`
			width: 100%;
			margin: 0;
		`}
`;