import { createAPIConnection } from 'services';
import { GetPointsResponse, GetTokenResponse } from './interfaces';
import { requestHandler } from '../helpers';

const api = createAPIConnection('/fidelity');

export const getToken = async (posId: string) => {
	return requestHandler<GetTokenResponse>(api.get(`/${posId}/validate`));
};

export const getPoints = async (posId: string) => {
	return requestHandler<GetPointsResponse>(api.get(`/${posId}`));
};
