import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { getUserFromLocalStorage, sendCustomIntegrations } from './helpers';
import { generateAssetsHash } from 'shared/hashes';
import { getStore } from 'stores/helpers';

export default function sentryInit() {
  if (!window.sessionStorage.getItem('@PHC:userTabSession')) {
    window.sessionStorage.setItem('@PHC:userTabSession', generateAssetsHash())
  }

  const { id, name, email } = getUserFromLocalStorage()?.data || {}

  Sentry.setUser(null);

  if (id) {
    Sentry.setUser({ email, id, username: name });
  }

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN || '',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        unblock: [".sentry-unblock, [data-sentry-unblock]"],
        unmask: [".sentry-unmask, [data-sentry-unmask]"],
        maskAllText: false,
      }),
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['debug', 'log', 'error'] }),
      Sentry.httpClientIntegration(),
      Sentry.httpContextIntegration(),
      Sentry.moduleMetadataIntegration(),
      sendCustomIntegrations()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    sendDefaultPii: true,
  });
}