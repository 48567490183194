import sentryInit from 'services/sentry';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { onEnvironment } from 'shared/environment';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './stores/store';
import 'material-symbols';

import 'front-commons/prototypes/promises';
import 'front-commons/prototypes/arrays';
import 'front-commons/prototypes/numbers';
import 'front-commons/prototypes/strings';
import 'front-commons/prototypes/dates';
import 'front-commons/prototypes/objects';
import 'front-commons/prototypes/errors';

import 'front-commons/entities/CPF';
import 'front-commons/entities/CNPJ';
import 'front-commons/entities/Phone';
import 'front-commons/entities/Email';
import 'front-commons/entities/Truthy';
import 'front-commons/entities/Customer';
import 'front-commons/entities/Link';
import 'shared/gtm/entity';

sentryInit();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const handleBeforeLift = () => {
	window.localStorage.setItem(
		'persist:@PHC',
		JSON.stringify({
			basketReducer: {},
		}),
	);
};

async function defferRender() {
	if (onEnvironment('production')) return;

	const { worker } = await import('./mocks/browser');

	if (JSON.parse(import.meta.env.VITE_INTERCEPT_REQUESTS || 'false')) worker.start();
}

defferRender().finally(() => {
	root.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor} onBeforeLift={handleBeforeLift}>
				<App />
			</PersistGate>
		</Provider>,
	);
});

reportWebVitals();
