import { Dispatch } from '@reduxjs/toolkit';
import {
	setLoading,
	setIsOwner,
	setToken,
	setStatus,
	clearFidelity,
	setFidelityData,
	setLoadingToken,
	setFidelityFlags,
	setRetryFetchData,
} from './actions';
import { FidelityFlagsParams, FidelityStatus, HandleFidelityParams, HandleRetryFetchDataParams } from './interfaces';
import { getPoints, getToken } from 'services/fidelity';
import { RoleTypes } from 'stores/pos/interfaces';
import dayjs from 'dayjs';
import { Redux } from 'stores/interfaces';
import { notify } from 'front-commons/ds';
import { CustomError } from 'shared/errors';

export const handleClearFidelity = (): any => {
	return (dispatch: Dispatch) => {
		dispatch(clearFidelity());
	};
};

export const handleFidelityFlags = (flags: FidelityFlagsParams): any => {
	return (dispatch: Dispatch) => {
		dispatch(setFidelityFlags(flags));
	};
};

export const handleRetryFetchData = ({ period, periodIn }: HandleRetryFetchDataParams): any => {
	return (dispatch: Dispatch) => {
		const expirationDate = dayjs().add(period, periodIn);

		dispatch(setRetryFetchData(expirationDate.format()));
	};
};

export const handleClearRetryFetchData = (): any => {
	return (dispatch: Dispatch) => {
		dispatch(setRetryFetchData(undefined));
	};
};

export const handleLoading = (state: boolean): any => {
	return (dispatch: Dispatch) => {
		dispatch(setLoading(state));
	};
};

export const handleLoadingToken = (state: boolean): any => {
	return (dispatch: Dispatch) => {
		dispatch(setLoadingToken(state));
	};
};

export const handleSetIsOwner = (role?: RoleTypes): any => {
	return (dispatch: Dispatch) => {
		dispatch(setIsOwner(role === 'OWNER'));
	};
};

export const handleSetToken = (posId: string): any => {
	return async (dispatch: Dispatch) => {
		try {
			const { token, ...flags } = await getToken(posId);
			dispatch(setToken(token));
			dispatch(handleFidelityFlags(flags));

			return token;
		} catch (error) {
			console.log(error);
			throw new CustomError('Não foi possível buscar o token dessa farmácia.', `token-custom-error-${error}`);
		}
	};
};

export const handleSetStatus = (status: FidelityStatus): any => {
	return async (dispatch: Dispatch) => {
		dispatch(setStatus(status));
	};
};

export const handleNavigateToFidelityPage = (posId: string): any => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(handleLoadingToken(true));
			const token = await dispatch(handleSetToken(posId));
			window.open(`${import.meta.env.VITE_BONI_REDIRECT}?token=${token}`);
		} catch {
			notify.negative({
				description: 'Não foi possível navegar para a página da fidelidade! Por favor, tente novamente.',
			});
		} finally {
			dispatch(handleLoadingToken(false));
		}
	};
};

export const handleSetFidelityData = ({ posId = '', role }: HandleFidelityParams): any => {
	return async (dispatch: Dispatch, getStore: () => Redux) => {
		try {
			dispatch(handleLoading(true));
			dispatch(handleSetIsOwner(role));
			await dispatch(handleSetToken(posId));
			const points = await getPoints(posId);

			if (!points.optIn && getStore().fidelityReducer.retryFetchData) {
				dispatch(handleRetryFetchData({ period: 1, periodIn: 'minutes' }));
			} else {
				dispatch(handleClearRetryFetchData());
			}

			dispatch(handleSetStatus('REGISTERED'));
			dispatch(
				setFidelityData({
					...points,
					goals: points.goals.map((goal) => ({ ...goal, goalName: goal.name })),
				}),
			);
		} catch (error) {
			dispatch(clearFidelity());
			if (error instanceof CustomError) {
				if (error.code?.includes('token-custom-error-')) {
					const tokenErrorStatus = error.code.replace('token-custom-error-', '');
					dispatch(handleSetStatus(tokenErrorStatus === 'forbidden' ? 'ENABLED' : 'DISABLED'));
					return;
				}
			}
		} finally {
			dispatch(handleLoading(false));
		}
	};
};
