import { Flex, Toaster } from 'front-commons/ds';
import { useStorageWatcher } from 'front-commons/hooks';
import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Footer from 'containers/Footer';
import Header from 'containers/Headers/Header';
import { storageKeys } from 'shared/storage';
import useCustomer from 'stores/customer';
import useDrawer from 'stores/drawer';
import useFeatures from 'stores/features';
import usePos from 'stores/pos';
import { store } from 'stores/store';
import {
	canAccessThisPage,
	comparePaths,
	getStartedFromState,
	handleWatchCustomerPhone,
	needRedirectByContext,
} from './helpers';
import { PageGlobalProps } from './interfaces';
import paths from './pages';

function ProtectedRoute({
	protectType: pageType,
	redirectTo,
	headerType,
	needContexts,
	showFooter = true,
}: PageGlobalProps) {
	useStorageWatcher({ key: storageKeys.TOKEN, methods: ['removeItem', 'setItem'], storageType: 'localStorage' });

	const location = useLocation();
	const navigate = useNavigate();
	const { customerStore, handleResetUserIsLoggingOut, handleSetLogout, mainCustomerPage } = useCustomer();
	const { featuresStore: features } = useFeatures();
	const { drawerStore } = useDrawer();
	const { posStore } = usePos();

	const hasNotAnyOpenedDrawer = useMemo(() => {
		return !drawerStore.drawers.filter((drawer) => drawer.open).length;
	}, [drawerStore.drawers]);

	const pharmacy = posStore.selectedPos;
	const { token, data: customer, provider: customerProvider } = customerStore;

	const initialToken = useMemo(() => store.getState().customerReducer.token, []);

	const volativeHeaderType = headerType === 'logged_and_not_logged' && customer ? 'logged' : 'not_logged';

	const redirect = {
		private: !token && '/login?logout=rdt',
		not_logged: !!token && customer && (redirectTo || mainCustomerPage()),
		public: false,
		by_context: needRedirectByContext(needContexts, { pharmacyContext: pharmacy, customerContext: customer }),
		external_provider: canAccessThisPage(customerProvider, location, token),
	};

	useEffect(() => {
		handleWatchCustomerPhone(
			customer.phoneNumber,
			customer.phoneNumberConfirmed,
			customer.id,
			location.pathname,
			pageType,
			navigate,
			features,
		);

		const atualPage = paths.find((path) => comparePaths(path.pathname, location.pathname));

		if (atualPage) {
			const externalCustomer = customerProvider !== 'PARCEIRO_HYPERA' || customerProvider === null;

			if (atualPage.protectType !== 'external_provider' && externalCustomer) {
				handleSetLogout(true);
			}

			if (atualPage.protectType === 'private' && !token) {
				// window.location.replace('/login');
				// navigate(0);
				navigate('/login?logout=usf', { replace: true });
			}

			if (atualPage.pathname === '/minhas-farmacias' && !location.state) {
				handleResetUserIsLoggingOut();
			}
		}
	}, [location]);

	useEffect(() => {
		if (!initialToken) {
			handleResetUserIsLoggingOut();
		}
	}, []);

	if (pageType && redirect[pageType]) {
		return (
			<Navigate
				to={String(redirect[pageType])}
				replace
				state={getStartedFromState({ pathnameRedirecting: redirect[pageType], location, hasToken: !!initialToken })}
			/>
		);
	}

	return (
		<>
			<Flex display="initial" position="sticky" top="0" zIndex={100}>
				{headerType && headerType !== 'hide' && (
					<Header type={headerType === 'logged_and_not_logged' ? volativeHeaderType : headerType} />
				)}

				{hasNotAnyOpenedDrawer && <Toaster target="desktop" />}
			</Flex>

			{hasNotAnyOpenedDrawer && <Toaster target="mobile" />}
			<Outlet />
			{showFooter && <Footer />}
		</>
	);
}

export default ProtectedRoute;
