import { Button, Flex, Radio, Grid, Icon, Tooltip, Typography, notify } from 'front-commons/ds';
import { useDynamicLoading } from 'front-commons/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ChangeDistributorProductCard from 'containers/Cards/ChangeDistributorProductCard';
import DistributorsSelector from 'containers/Distributors/DistributorsSelector';
import ValuesSummary from 'containers/Summaries/ValuesSummary';
import { replaceProductsUnavailable } from 'services/basket';
import { BusinessUnitProps } from 'services/basket/interfaces';
import { getProductsDetails } from 'services/products';
import { DistributorStatus, DistributorsProps, ProductParams } from 'services/products/interfaces';
import { updateProviderGTM } from 'shared/gtm';
import useBasket from 'stores/basket';
import useDrawer from 'stores/drawer';
import usePos from 'stores/pos';
import { getNotificationMessage } from './helpers';
import { BuProductsParams, DistributorChangeProps } from './interfaces';
import DistributorChangeSkeleton from './Skeleton';
import { BUContainer, CTAContainer, Container, Content, SummaryContainer } from './styles';

export default function DistributorChange({ products, initialProductDistributor }: DistributorChangeProps) {
	const {
		handleGetBasketData,
		handleRemoveFromBasket,
		basketStore: { basket: basketData },
	} = useBasket();

	const { posStore } = usePos();
	const posId = posStore.selectedPos.id;
	const { handleLoading } = useDynamicLoading();
	const { handleSetDynamicTitle, handleCloseAllDrawers, drawerStore } = useDrawer();
	const [buProducts, setBuProducts] = useState<BuProductsParams[]>([]);
	const [options, setOptions] = useState<GenericLabelValue[]>([]);
	const [selectedBU, setSelectedBU] = useState<string>();
	const [massiveDistributor, setMassiveDistributor] = useState<DistributorsProps>();
	const [productsDistributor, setProductsDistributor] = useState<Record<string, DistributorsProps>>();

	const summaryValues = useMemo(() => {
		return Object.values(productsDistributor || {}).reduce(
			(acc, curr) => {
				// @ts-ignore
				const quantity = Math.round((curr.finalTotalPrice || 1) / (curr.finalUnitPrice || 1));
				const brute = (curr.unitPrice || 0) * quantity;
				const discount = ((curr.discount || 0) / 100) * brute;
				const tax = curr.taxSubstitution || 0;

				return {
					discount: discount + acc.discount,
					brute: brute + acc.brute,
					tax: tax + acc.tax,
				};
			},
			{ discount: 0, brute: 0, tax: 0 },
		);
	}, [productsDistributor]);
	const currentBuProducts = buProducts?.find(({ buId }) => buId === selectedBU)?.products;
	const currentBuDistributors = useMemo(() => {
		const allDistributors = currentBuProducts?.reduce((acc, curr) => {
			return [
				...acc,
				...curr.distributors.filter(
					(distributor) => !acc.some(({ distributorId }) => distributorId === distributor.distributorId),
				),
			];
		}, [] as DistributorsProps[]);

		return allDistributors?.reduce((acc, curr) => {
			const isUnavailable = currentBuProducts
				?.flatMap(({ distributors }) => distributors)
				.some(({ distributorId, status }) => distributorId === curr.distributorId && status === 'UNAVAILABLE');

			return isUnavailable
				? acc
				: [
						...acc,
						{
							distributorId: curr.distributorId,
							distributorName: curr.distributorName,
							order: 0,
							status: 'AVAILABLE' as DistributorStatus,
						},
				  ];
		}, [] as DistributorsProps[]);
	}, [currentBuProducts]);
	const showRadioOptions = options.length > 1;
	const currentBUName = options.find(({ value }) => value === selectedBU)?.label;
	const productsLength = buProducts.find(({ buId }) => buId === selectedBU)?.products.length;

	const handleSelectBU = useCallback(({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		handleLoading().set(['REMOVE_PRODUCTS']);
		setSelectedBU((prevState) => {
			if (value !== prevState) setProductsDistributor(undefined);
			return value;
		});

		setTimeout(() => {
			setMassiveDistributor(undefined);
			setTimeout(() => {
				handleLoading().remove(['REMOVE_PRODUCTS']);
			}, 200);
		}, 100);
	}, []);

	const handleSelectMassiveDistributor = useCallback((distributor: DistributorsProps) => {
		setMassiveDistributor(distributor);
	}, []);

	const handleDrawerClose = () => {
		handleLoading().remove(['REMOVE_PRODUCTS', 'CHANGING_DISTRIBUTORS', 'PRODUCTS_LIST']);
		setBuProducts([]);
		setOptions([]);
		handleSetDynamicTitle('distributor-change-drawer', 0);
		setMassiveDistributor(undefined);
		setProductsDistributor(undefined);
		setSelectedBU(undefined);
	};

	const handleFetchList = useCallback(
		async (productsToChange: DistributorChangeProps['products']) => {
			try {
				handleLoading().set(['PRODUCTS_LIST']);

				const data = await getProductsDetails(posId, { products: productsToChange });
				const parsedProducts = data.products.reduce((acc, curr) => {
					if (!acc.some(({ buName }) => buName === curr.businessUnitName)) {
						const buName = curr.businessUnitName!;
						const buId = curr.businessUnitId!;
						setOptions((prevState) =>
							[...prevState, { label: buName, value: buId }].sort((a, b) => {
								if (a.label > b.label) return 1;
								if (a.label < b.label) return -1;
								return 0;
							}),
						);

						return [
							...acc,
							{
								buName,
								buId,
								products: [curr],
							},
						];
					}

					return acc.map((bu) => {
						if (bu.buName !== curr.businessUnitName) return bu;
						return { ...bu, products: [...(bu.products || []), curr] };
					});
				}, [] as BuProductsParams[]);

				const currBU =
					parsedProducts.length > 1
						? parsedProducts.find(({ buName }) => buName === 'Marcas')?.buId
						: parsedProducts[0].buId;

				setSelectedBU(currBU);
				handleSetDynamicTitle(
					'distributor-change-drawer',
					parsedProducts.find(({ buName }) => buName === currBU)?.products.length,
				);
				setBuProducts(parsedProducts);
			} catch {
				notify.negative({ description: 'Falha ao buscar dados dos produtos.' });
				handleCloseAllDrawers();
			} finally {
				handleLoading().remove(['PRODUCTS_LIST']);
			}
		},
		[posId],
	);

	const handleSingleSelectDistributor = useCallback((productId: string, distributor: DistributorsProps) => {
		setProductsDistributor((prevState) => ({ ...prevState, [productId]: distributor }));
	}, []);

	const handleRemoveSingleProduct = useCallback(
		async (productId: string, preventFetch = false) => {
			try {
				handleLoading().set(['REMOVE_PRODUCTS']);

				if (!preventFetch) await handleRemoveFromBasket(productId);

				setBuProducts((prevState) => {
					return prevState.reduce((acc, curr) => {
						if (curr.buId !== selectedBU) return [...acc, curr];

						if (curr.products.length === 1) {
							if (prevState.length === 2) {
								setOptions((prevOptions) => {
									const otherBu = prevOptions.filter(({ value }) => value !== selectedBU);
									setSelectedBU(otherBu[0].value);

									return otherBu;
								});
							}

							notify.info({ description: `Não há mais produtos a serem alterados em ${currentBUName}.` });

							if (prevState.length === 1) {
								handleCloseAllDrawers();
							}

							return acc;
						}

						return [
							...acc,
							{
								...curr,
								products: curr.products.filter(({ id }) => id !== productId),
							},
						];
					}, [] as BuProductsParams[]);
				});

				setProductsDistributor((prevState) => {
					return Object.entries(prevState || {}).reduce((acc, [key, value]) => {
						return key !== productId ? { ...acc, [key]: value } : acc;
					}, {});
				});
			} catch {
				notify.negative({ description: 'Falha ao remover arquivo do carrinho.' });
			} finally {
				handleLoading().remove(['REMOVE_PRODUCTS']);
			}
		},
		[selectedBU],
	);

	const handleAlterNotification = useCallback(
		(alteredDistributorsQnt: number, prevDistributorsQnt: number) => {
			const text = getNotificationMessage(alteredDistributorsQnt, prevDistributorsQnt, currentBUName!);

			if (alteredDistributorsQnt === prevDistributorsQnt) {
				if (showRadioOptions) {
					setOptions((prevState) =>
						prevState.filter(({ value }) => {
							const isOther = value !== selectedBU;

							if (isOther && value) handleSelectBU({ currentTarget: { value } } as any);

							return isOther;
						}, [] as GenericLabelValue[]),
					);

					return notify.positive({ description: text });
				}

				handleCloseAllDrawers();
				return notify.positive({ description: text });
			}

			return notify.positive({ description: text });
		},
		[selectedBU, showRadioOptions],
	);

	const handleAlterDistributors = async () => {
		if (!currentBuProducts || !products || !initialProductDistributor || !productsDistributor) return;

		const changedProducts = products.reduce((acc, { productId, quantity, productsInCombo }) => {
			if (!productsDistributor[productId]) return acc;

			const initialDistributor: string = initialProductDistributor[productId];
			const currentDistributor = productsDistributor[productId].distributorId;

			if (initialDistributor === currentDistributor) return acc;

			const moreData = currentBuProducts.find((product) => product.id === productId);

			return [
				...acc,
				{
					productId,
					quantity,
					distributorId: currentDistributor,
					productsInCombo,
					moreData,
				},
			];
		}, [] as BusinessUnitProps['products']);

		if (!changedProducts.length) {
			notify.warn({ description: 'Nenhuma alteração foi feita. Escolha um distribuidor para efetuar a alteração.' });
			return;
		}

		try {
			handleLoading().set(['CHANGING_DISTRIBUTORS']);
			await replaceProductsUnavailable(posId, {
				businessUnitId: selectedBU!,
				products: changedProducts.map(({ productId, quantity, distributorId, productsInCombo }) => ({
					productId,
					quantity,
					distributorId,
					productsInCombo,
				})),
			});

			const changedProductsInfos = (changedProducts as any[]).map(
				(product: { productId: string; quantity: number; distributorId: string; moreData: ProductParams }) => {
					const selectedDistributor = product.moreData.distributors.find(
						({ distributorId }) => distributorId === product.distributorId,
					);

					const totalPrice = (selectedDistributor?.unitPrice || 0) * product.quantity;
					const finalTotalPrice = (selectedDistributor?.unitPricePromotion || 0) * product.quantity;
					const discount = Number((totalPrice - finalTotalPrice).toFixed(2)) || 0;

					return {
						id: product.productId,
						ean13: product.moreData.ean13 || '',
						productType: product.moreData.productType,
						description: product.moreData.description,
						businessUnitName: product.moreData.businessUnitName,
						quantity: product.quantity,
						brandName: product.moreData.brandName,
						price: Number(finalTotalPrice.toFixed(2)),
						discount,
						affiliation: selectedDistributor?.distributorName || '',
					};
				},
			);

			updateProviderGTM(changedProductsInfos, basketData);

			handleAlterNotification(changedProducts.length, currentBuProducts.length);

			changedProducts.forEach(({ productId }) => {
				handleRemoveSingleProduct(productId, true);
			});

			handleGetBasketData({ loading: 'refetch', initializeBefore: true });
		} catch {
			notify.negative({
				description: 'Não foi possível realizar a alteração dos distribuidores para os produtos selecionados.',
			});
		} finally {
			handleLoading().remove(['CHANGING_DISTRIBUTORS']);
		}
	};

	useEffect(() => {
		handleSetDynamicTitle('distributor-change-drawer', productsLength);
	}, [productsLength]);

	useEffect(() => {
		const isDrawerClosed = !drawerStore.drawers.find(({ id }) => id === 'distributor-change-drawer')?.open;

		if (isDrawerClosed) {
			handleDrawerClose();
			return;
		}

		handleFetchList(products);
	}, [drawerStore.drawers]);

	if (handleLoading().get(['PRODUCTS_LIST']) || !selectedBU) return <DistributorChangeSkeleton />;

	return (
		<Container direction="column" gap="0px">
			<BUContainer margin={{ small: '0 16px', medium: '0 64px' }} padding="8px 0 24px" gap="8px" alignItems="center">
				{showRadioOptions ? (
					<>
						<Typography variant="ParagraphSmall/Regular" color="--text-primary">
							Filtrar por:
						</Typography>
						<Radio
							variant="16px"
							name="buSelector"
							onChange={handleSelectBU}
							value={selectedBU}
							outerGap="16px"
							data={options}
						/>
					</>
				) : (
					<Flex>
						<Typography variant="Paragraph/Semibold" color="--text-link">
							{currentBUName}
						</Typography>
					</Flex>
				)}
			</BUContainer>
			<Content
				padding={{ small: '24px 16px', medium: '24px 64px' }}
				direction="column"
				gap="24px"
				height={{
					small: `calc(100vh - ${showRadioOptions ? 207 : 200}px)`,
					medium: `calc(100vh - ${showRadioOptions ? 231 : 230}px)`,
				}}
			>
				<Flex
					direction={{ small: 'column', medium: 'row' }}
					justifyContent="space-between"
					width="100%"
					margin="0 0 8px 0"
					padding={{ small: '0', medium: '0 56px 0 0' }}
					alignItems={{ small: 'flex-start', medium: 'center' }}
				>
					<Typography variant="ParagraphSmall/Regular" color="--text-primary">
						Alterar todos os distribuidores para:
					</Typography>

					<Grid columns={{ small: 'calc(100vw - 32px)', medium: '268px' }} maxWidth="fit-content">
						<DistributorsSelector
							data={currentBuDistributors || []}
							onChange={handleSelectMassiveDistributor}
							showFooter={false}
							size="small"
							permitEmptyValue
						/>
					</Grid>
				</Flex>

				{currentBuProducts &&
					currentBuProducts.map((product) => (
						<ChangeDistributorProductCard
							key={product.id}
							product={product}
							basketSelectedDistributorId={initialProductDistributor?.[product.id]}
							massiveDistributorId={massiveDistributor?.distributorId}
							onDistributorSelect={handleSingleSelectDistributor}
							distributor={productsDistributor?.[product.id]}
							onRemove={handleRemoveSingleProduct}
							isDeleting={handleLoading().get(['REMOVE_PRODUCTS', 'CHANGING_DISTRIBUTORS'])}
						/>
					))}

				<SummaryContainer direction="column" gap="16px">
					<Flex alignItems="center" gap="8px">
						<Typography variant="Paragraph/Semibold" style={{ fontSize: '20px' }}>
							Resumo
						</Typography>
						<Tooltip content="Alguns produtos podem sofrer alteração de preço por causa de impostos aplicados pelos distribuidores, os novos valores serão informados na nota fiscal.">
							<Icon name="info" size="16px" />
						</Tooltip>
					</Flex>
					<ValuesSummary {...summaryValues} />
				</SummaryContainer>
			</Content>
			<CTAContainer padding={{ small: '16px', medium: '16px 64px' }} margin="0 0 72px 0">
				<Button
					width={{ small: '100%', medium: '150px' }}
					loading={handleLoading().get(['REMOVE_PRODUCTS', 'CHANGING_DISTRIBUTORS'])}
					onClick={handleAlterDistributors}
				>
					Alterar
				</Button>
			</CTAContainer>
		</Container>
	);
}
