import { Flex, Typography, notify } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import { useDynamicLoading } from 'front-commons/hooks';
import { useEffect, useMemo, useState } from 'react';
import UndoSuggestionCard from 'containers/Cards/UndoSuggestionCard';
import { ChangedPromotionProps } from 'pages/checkout/EndBasket/interfaces';
import { replaceProducts } from 'services/basket';
import { ProductsToReplaceRequest } from 'services/basket/interfaces';
import { ProductInPromotionParams } from 'services/products/interfaces';
import useBasket from 'stores/basket';
import useDrawer from 'stores/drawer';
import usePos from 'stores/pos';
import {
	ChangingStates,
	ChangingTypes,
	Handlers,
	ProductChangeQueue,
	ProductsDataProps,
	PromotionSuggestionProps,
	QueueProps,
	SummaryProps,
} from './interfaces';
import PromotionSuggestionCard from './PromotionSuggestionCard';
import { CTAContainer, Container, Content, SuggestionButton } from './styles';
import SummaryAccordion from './SummaryAccordion';

export default function PromotionSuggestion({ data }: Partial<PromotionSuggestionProps>) {
	const { posStore } = usePos();
	const { handleGetBasketData } = useBasket();
	const { handleUpdateDrawerProps, handleCloseAllDrawers, drawerStore } = useDrawer();
	const { handleLoading } = useDynamicLoading<'SOME' | 'EVERY'>();

	const drawerState = useMemo(() => drawerStore.drawers.find(({ id }) => id === 'promotion-suggestion'), [drawerStore]);

	const [productsData, setProductsData] = useState({} as ProductsDataProps);
	const [changingQueue, setChangingQueue] = useState({} as ProductChangeQueue);

	const posId = posStore.selectedPos.id;
	const hasSomeLoading = handleLoading().get(['EVERY', 'SOME']);
	const replaceableQuantity = data?.length || 1;

	/** Helper to lead with product state */
	const stateHandler = {
		get(simpleProductId: string | 'EVERY') {
			return productsData[simpleProductId]?.state;
		},

		update(simpleProductId: string | 'EVERY', newState: ChangingStates, prevState?: ChangingStates) {
			if (simpleProductId === 'EVERY' && prevState) {
				setProductsData((products) => {
					const entries = Object.entries(products);

					return entries.reduce((acc, [key, value]) => {
						if (value.state !== prevState)
							return {
								...acc,
								[key]: value,
							};

						return {
							...acc,
							[key]: {
								...products[key],
								state: newState,
							},
						};
					}, {} as ProductsDataProps);
				});
				return;
			}

			setProductsData((products) => ({
				...products,
				[simpleProductId]: {
					...products[simpleProductId],
					state: newState,
				},
			}));
		},

		hasSome(state: ChangingStates) {
			return Object.values(productsData).some((item) => item.state === state);
		},
	};

	/** Helper to lead with product summary */
	const summaryHandlers = {
		update(simpleProductId: string, payload: Partial<SummaryProps>) {
			setProductsData((prevState) => ({
				...prevState,
				[simpleProductId]: {
					...prevState[simpleProductId],
					summary: {
						...prevState[simpleProductId]?.summary,
						...payload,
					},
				},
			}));
		},

		get() {
			return Object.values(productsData).reduce((acc, { simpleData: { id }, summary: tempRename }) => {
				return {
					...acc,
					[id]: tempRename,
				};
			}, {});
		},
	};

	/** Helper to lead with product updated props */
	const promotionHandlers: Handlers['promotionHandlers'] = (simpleProductId: string) => {
		const handleUpdate = (payload: Partial<ChangedPromotionProps>) => {
			setProductsData((prevState) => ({
				...prevState,
				[simpleProductId]: {
					...prevState[simpleProductId],
					updatedData: {
						...prevState[simpleProductId].updatedData,
						...payload,
					},
				},
			}));
		};

		return {
			get: {
				quantity: productsData[simpleProductId]?.updatedData?.quantity || 1,
				distributorId: productsData[simpleProductId]?.updatedData?.distributorId,
			},
			set: {
				quantity(quantity: number) {
					handleUpdate({ quantity });
				},
				distributorId(distributorId: string) {
					handleUpdate({ distributorId });
				},
			},
			map: {
				productsInCombo(products: ProductInPromotionParams[], quantity, fixedAmount) {
					return products.map(({ productId, minimumQuantity }) => ({
						productId,
						quantity: fixedAmount ? minimumQuantity! : quantity!,
					}));
				},
			},
		};
	};

	/** Change available products */
	const handleChangeEveryInto = async (into?: ChangingTypes) => {
		const toPromotion = into === 'PROMOTION';
		const changedData = data!.reduce((acc, { simpleProductInfo, suggestion }) => {
			if (stateHandler.get(simpleProductInfo.id) !== (toPromotion ? 'READY' : 'PARTIALLY_EVERY_CHANGED')) return acc;

			const { get: updatedData, map } = promotionHandlers!(simpleProductInfo.id);

			if (toPromotion)
				return [
					...acc,
					{
						removeProductId: simpleProductInfo.id,
						addProduct: {
							productId: suggestion.id,
							distributorId: updatedData.distributorId,
							quantity: suggestion.fixedAmount ? updatedData.quantity : 1,
							productsInCombo: map.productsInCombo(
								suggestion.productsInCombo,
								updatedData.quantity,
								suggestion.fixedAmount,
							),
						},
					},
				];

			return [
				...acc,
				{
					addProduct: {
						productId: simpleProductInfo.id,
						distributorId: simpleProductInfo.currentDistributorId,
						quantity: simpleProductInfo.quantity,
					},
					removeProductId: suggestion.id,
				},
			];
		}, [] as ProductsToReplaceRequest[]);

		try {
			handleLoading().set(['EVERY']);
			await replaceProducts(posId, changedData);

			stateHandler.update(
				'EVERY',
				toPromotion ? 'PARTIALLY_EVERY_CHANGED' : 'READY',
				toPromotion ? 'READY' : 'PARTIALLY_EVERY_CHANGED',
			);
			setTimeout(() => {
				handleGetBasketData({ loading: 'refetch' });
			}, 500);
		} catch {
			notify.negative({ description: 'Falha ao tentar substituir todos os produtos por suas promoções' });
		} finally {
			handleLoading().remove(['EVERY']);
		}
	};

	/** Change one product */
	const handleChangeSomeInto = async ({ changeInto, data: changingData }: QueueProps, recursive = false) => {
		if (!recursive) {
			setChangingQueue((prevState) => ({
				...prevState,
				[changingData.removeProductId]: { changeInto, data: changingData },
			}));
		}

		if (Object.keys(changingQueue).length && !recursive) return;

		const toPromotion = changeInto === 'PROMOTION';
		const simpleProductId = toPromotion ? changingData.removeProductId : changingData.addProduct.productId;

		try {
			handleLoading().set(['SOME']);

			await replaceProducts(posId, [changingData]);

			stateHandler.update(simpleProductId, toPromotion ? 'PARTIALLY_CHANGED' : 'READY');
			setTimeout(() => {
				handleGetBasketData({ loading: 'refetch' });
			}, 500);
		} catch (error) {
			notify.negative({
				description: `Não conseguimos trocar seu produto para ${toPromotion ? 'combo' : 'produto simples'}.`,
			});

			stateHandler.update(simpleProductId, toPromotion ? 'READY' : 'CHANGED');
		} finally {
			setChangingQueue((prevState) => {
				const newQueue = { ...prevState };
				delete newQueue[changingData.removeProductId];

				const nextToFetch = Object.values(newQueue);
				if (nextToFetch.length) handleChangeSomeInto(nextToFetch[0], true);

				return newQueue;
			});

			handleLoading().remove(['SOME']);
		}
	};

	/** Mount core object when new data comes */
	useEffect(() => {
		if (!data) return;

		setProductsData(
			data.reduce((acc, { simpleProductInfo, suggestion }) => {
				return {
					...acc,
					[simpleProductInfo.id]: {
						promotionData: suggestion,
						simpleData: simpleProductInfo,
						state: 'READY',
						updatedData: {
							quantity: simpleProductInfo.quantity,
							distributorId: '',
						},
						summary: {
							discount: suggestion.discountToApply,
							quantity: suggestion.fixedAmount ? 1 : simpleProductInfo.quantity,
							distributors: suggestion.distributors,
							currentDistributorId: simpleProductInfo.currentDistributorId,
							basketQuantity: suggestion.fixedAmount ? 1 : simpleProductInfo.quantity,
							currentValue:
								suggestion.distributors.find(({ distributorId }) => {
									return distributorId === simpleProductInfo.currentDistributorId;
								})?.unitPricePromotion || 0,
						},
					},
				};
			}, {} as ProductsDataProps),
		);
	}, [data]);

	/** Prevent user closing basket when some product has been changed */
	useEffect(() => {
		handleUpdateDrawerProps('promotion-suggestion', {
			onClose: hasSomeLoading ? () => {} : handleCloseAllDrawers,
		});
	}, [hasSomeLoading]);

	/** To clean drawer after closed */
	useEffect(() => {
		if (!drawerState) setProductsData({});
	}, [drawerState]);

	if (!data) return null;

	return (
		<Container direction="column" gap="0px">
			<Content
				height={{
					small: `calc(100vh - 235px)`,
					medium: `calc(100vh - 265px)`,
				}}
				margin={{ small: '0 16px', medium: '0 56px' }}
			>
				{data.map((suggestionData, index, { length }) => {
					const isQueued = !!(
						changingQueue?.[suggestionData.simpleProductInfo.id] || changingQueue?.[suggestionData.suggestion.id]
					);

					return (
						<Flex
							key={index}
							padding="0 0 40px 0"
							border={`1px solid ${theme.colors['--border-primary']}`}
							borderWidth="0 0 1px 0"
							width="100%"
						>
							<PromotionSuggestionCard
								{...suggestionData}
								showReplaceButton
								onUndoTimeout={(simpleProductId) => stateHandler.update(simpleProductId, 'CHANGED')}
								promotionHandlers={promotionHandlers}
								loading={isQueued}
								handleChangeProductType={handleChangeSomeInto}
								updateSummary={summaryHandlers.update}
								itemState={stateHandler.get(suggestionData.simpleProductInfo.id)}
								hasOnlyOneSuggestion={length === 1}
							/>
						</Flex>
					);
				})}
			</Content>

			<CTAContainer padding={{ small: '16px', medium: '16px 64px' }}>
				<SummaryAccordion summaryHandlers={summaryHandlers} />

				<Flex width="100%" justifyContent="flex-end">
					{stateHandler.hasSome('PARTIALLY_EVERY_CHANGED') ? (
						<UndoSuggestionCard
							onUndo={() => handleChangeEveryInto('SIMPLE')}
							onTimeout={() => stateHandler.update('EVERY', 'PARTIALLY_EVERY_CHANGED', 'CHANGED')}
							loading={handleLoading().get(['EVERY'])}
							isMany
						/>
					) : (
						<SuggestionButton
							disabled={!stateHandler.hasSome('READY')}
							onClick={() => handleChangeEveryInto('PROMOTION')}
							loading={hasSomeLoading}
							minWidth="175px"
						>
							<Typography color="--text-invert">
								Substituir {replaceableQuantity > 1 ? 'todos' : 'por combo'}
							</Typography>
						</SuggestionButton>
					)}
				</Flex>
			</CTAContainer>
		</Container>
	);
}
